import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {getFilenameFromUrl} from "../../../common/data_helpers";


const AppAnnieDexcomStyleGraphs = (cumulativeGraphs = true, regions = ['US', 'WW']) => ({data: {data, meta}}) => {
    return <>
        {
            (regions.length > 1) ?
                regions.map(region => <AccordionRegionComponent data={data} region={region}
                                                                cumulativeGraphs={cumulativeGraphs}/>)
                : <AppAnnieExportStyleRegionGraphs data={data} region={regions[0]}/>
        }


        <div style={{height: '25px'}}></div>
        {meta.uploads.map(up => <div><a href={up.share_url} target={"_blank"}>{getFilenameFromUrl(up.share_url)}</a>
            <div></div>
        </div>)}

    </>
}


const AccordionRegionComponent = ({data, region, cumulativeGraphs}) => {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{region}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <AppAnnieExportStyleRegionGraphs data={data} region={region} cumulativeGraphs={cumulativeGraphs}/>
        </AccordionDetails>
    </Accordion>
}


const AppAnnieExportStyleRegionGraphs = ({data, region, cumulativeGraphs}) => {
    return <div>
        <TimeLinePlotWithSettings
            data={data['downloads'][region]['downloads']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads`}
        />
        <TimeLinePlotWithSettings
            data={data['downloads'][region]['downloads_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads YoY`}
            defaultMaxY={120}
            otherLineProps={{curve: 'linear'}}
        />
        <TimeLinePlotWithSettings
            data={data['downloads'][region]['downloads_mom']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads MoM`}
            defaultMaxY={120}
            otherLineProps={{curve: 'linear'}}

        />
        <AreaPlot
            data={data['downloads'][region]['downloads_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads Market Share`}
        />
        {cumulativeGraphs ? <TimeLinePlotWithSettings
            data={data['downloads'][region]['downloads_cumsum']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads Cumsum`}
        /> : null}
        {cumulativeGraphs ? <AreaPlot
            data={data['downloads'][region]['downloads_cs_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads CS Market Share`}
        /> : null}
        <TimeLinePlotWithSettings
            data={data['dau'][region]['dau']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU`}
            otherLineProps={{curve: 'linear'}}
        />
        <TimeLinePlotWithSettings
            data={data['dau'][region]['dau_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU YoY`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}

        />
        <TimeLinePlotWithSettings
            data={data['dau'][region]['dau_mom']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU MoM`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}

        />
        <AreaPlot
            data={data['dau'][region]['dau_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU Market Share`}
        />
        <TimeLinePlotWithSettings
            data={data['mau'][region]['mau']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU`}
        />
        <TimeLinePlotWithSettings
            data={data['mau'][region]['mau_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU YoY`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}

        />
        <TimeLinePlotWithSettings
            data={data['mau'][region]['mau_mom']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU MoM`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}

        />
        <AreaPlot
            data={data['mau'][region]['mau_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU Market Share`}
        />
    </div>
}

export default AppAnnieDexcomStyleGraphs
