export let convert = (data) => {
    let dates = Object.keys(data)
    dates.sort()
    return dates.map(date => ({x: date, y: round2Decimal(data[date])}))
}

export let addId = (data, id) => {
    return data.map((obj) => ({...obj, id}))
}

let round2Decimal = (value) => {
    if (value === null) {
        return null
    } else {
        return Math.round(value * 100) / 100
    }
}


export let capY = (series, MAX_Y_VALUE, MIN_Y_VALUE,yFormatFun) => {
    return series.map(point => {
        if (point.y > MAX_Y_VALUE) {
            return {
                ...point,
                originalY: point.y,
                originalYFormatted: yFormatFun(point.y),
                y: MAX_Y_VALUE,
                isModified: true
            }
        } else if (point.y < MIN_Y_VALUE) {
            return {
                ...point,
                originalY: point.y,
                originalYFormatted: yFormatFun(point.y),
                y: MIN_Y_VALUE,
                isModified: true
            }
        } else {
            return {
                ...point,
                isModified: false
            };
        }
    });
}

export let findLargestY = (nestedDict) => {
    let largestY = -Infinity; // Initialize to the smallest possible number

    // Iterate through each key in the outer dictionary
    for (let outerKey in nestedDict) {
        // Ensure the key is actually part of the dictionary and not inherited
        if (nestedDict.hasOwnProperty(outerKey)) {
            // Access the nested dictionary
            let innerDict = nestedDict[outerKey];

            // Iterate through each value in the inner dictionary
            for (let innerKey in innerDict) {
                // Ensure the key is actually part of the inner dictionary and not inherited
                if (innerDict.hasOwnProperty(innerKey)) {
                    let value = innerDict[innerKey];

                    // Check if the value is a number and update largestY if it's greater
                    if (typeof value === "number") {
                        largestY = Math.max(largestY, value);
                    }
                }
            }
        }
    }

    return largestY; // Return the largest y value found
}


export let findSmallestY = (nestedDict) => {
    let smallestY = Infinity; // Initialize to the smallest possible number

    // Iterate through each key in the outer dictionary
    for (let outerKey in nestedDict) {
        // Ensure the key is actually part of the dictionary and not inherited
        if (nestedDict.hasOwnProperty(outerKey)) {
            // Access the nested dictionary
            let innerDict = nestedDict[outerKey];

            // Iterate through each value in the inner dictionary
            for (let innerKey in innerDict) {
                // Ensure the key is actually part of the inner dictionary and not inherited
                if (innerDict.hasOwnProperty(innerKey)) {
                    let value = innerDict[innerKey];

                    // Check if the value is a number and update largestY if it's greater
                    if (typeof value === "number") {
                        smallestY = Math.min(smallestY, value);
                    }
                }
            }
        }
    }

    return smallestY; // Return the largest y value found
}
