import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {roundNumber} from "../../../common/data_helpers";
import {findSmallestY} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import {noFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import withDataFetchAndTitle from "../../components/DataLoadPage";

const ReviewsGraphs = ({data: {review_graph}}) => {
    return <div>
        <TimeLinePlotWithSettings data={review_graph['ratings']} title='Ratings'
                                  defaultSmoothing={6}
                                  smoothingUnit={"months"}
                                  defaultMinY={roundNumber(findSmallestY(review_graph['ratings']), 2) - 0.01}
                                  yFormatFun={noFormat}
                                  colorScheme={"spectral"}

        />

        <TimeLinePlotWithSettings data={review_graph['counts']} title='Review Counts'
                                  defaultSmoothing={6}
                                  smoothingUnit={"months"}
                                  colorScheme={"spectral"}
                                  yFormatFun={noFormat}/>


    </div>
}


export default withDataFetchAndTitle(ReviewsGraphs, 'autodesk/g2', 'Autodesk - G2 Reviews')