import React from "react";
import {getUniqueKeysOfNestedDicts, roundNumber} from "../../../common/data_helpers";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";

const AppAnnieSummary = ({data}) => {
    return <div>
        <DataTable
            data={data['downloads_abs']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"Downloads [thousands]"}
        />
        <div style={{height: 25}}></div>
        <DataTable
            data={data['downloads_yoy']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"Downloads [YoY]"}
        />
        <div style={{height: 25}}></div>

        <DataTable
            data={data['dau_abs']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"DAU [thousands]"}
        />
        <div style={{height: 25}}></div>
        <DataTable
            data={data['dau_yoy']}
            numberFormat={(num) => roundNumber(num, 0)}
            title={"DAU [YoY]"}
        />

    </div>
}


export const DataTable = ({data, numberFormat, title, dropQuarter = true}) => {
    let quarters = getUniqueKeysOfNestedDicts(data);
    let lastCompleteQuarter = dropQuarter ? quarters[quarters.length - 2]:quarters[quarters.length - 1] ;
    if(dropQuarter) {
        quarters.pop()
    }

    const keys = Object.keys(data);

    keys.sort((keyA, keyB) => {
        return (data[keyB][lastCompleteQuarter] || -Infinity) - (data[keyA][lastCompleteQuarter] || -Infinity)
    })


    let styling = {fontSize: '0.9rem', padding: '3px'}
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        },
    }));

    return (

        <TableContainer component={Paper}>
            <Typography>{title}</Typography>
            <Table>
                <TableHead style={{borderBottom: '2px solid #000'}}>
                    <TableRow>
                        <TableCell sx={styling}>App</TableCell>
                        {quarters.map((column, index) => (
                            <TableCell key={column} sx={styling}>
                                {column}
                            </TableCell>
                        ))}
                        <TableCell sx={styling}>App</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keys.map((key) => (
                        <StyledTableRow key={key}>
                            <TableCell component="th" scope="row" sx={styling}>
                                {key}
                            </TableCell>
                            {quarters.map((column) => (
                                <TableCell key={column} sx={styling}>
                                    {data[key][column] ? numberFormat(data[key][column]) : ""}
                                </TableCell>
                            ))}
                            <TableCell component="th" scope="row" sx={styling}>
                                {key}
                            </TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default AppAnnieSummary