import React, {useRef} from "react";
import Typography from "@mui/material/Typography";
import {ResponsiveLine, ResponsiveLineCanvas} from "@nivo/line";
import Button from "@mui/material/Button";
import {exportComponentAsPNG} from "react-component-export-image";
import Paper from "@mui/material/Paper";
import CustomSliceTooltip from "../Tooltips/CustomSliceTooltip";
import {numberWithCommas} from "../common";
import {getMobileAdjustedHeight, useWindowSize} from "../plotsHelpers";
import Box from "@mui/material/Box";
import RenderIfVisible from "react-render-if-visible";
import TextField from "@mui/material/TextField";


const GraphComponent = React.forwardRef(({
                                             data, title, xaxis, stacked = false,
                                             axisBottomParams = {},

                                             GraphComp = ResponsiveLineCanvas,
                                             ...comps

                                         }, ref) => {
    const {height} = useWindowSize();

    return <div ref={ref}
                style={{height: getMobileAdjustedHeight(height, 500) - 60,}}>
        <Typography variant="h6" color="inherit" component="div" align="center">
            {title}
        </Typography>
        <GraphComp
            data={data}
            margin={{top: 15, right: 150, bottom: 65, left: 100}}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: stacked,
                min: 'auto',
                // nice: true
            }}
            enableArea={stacked}
            axisLeft={{
                legend: xaxis,
                legendOffset: 12,
                format: numberWithCommas
            }}
            axisBottom={{
                format: '%y %b',
                legend: 'year month',
                legendOffset: -12,
                ...axisBottomParams
            }}
            pointSize={0}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            // pointSymbol={CustomSymbol}
            useMesh={true}
            enableSlices="x"
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 5,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    // effects: [
                    //     {
                    //         on: 'hover',
                    //         style: {
                    //             itemBackground: 'rgba(0, 0, 0, .1)',
                    //             itemOpacity: 1,
                    //         }
                    //     }
                    // ]
                }
            ]}
            sliceTooltip={CustomSliceTooltip(true)}
            {...comps}
        />
    </div>
})

let GraphWithPrint = ({csv = false, ...comps}) => {
    const {height} = useWindowSize();


    const componentRef = useRef();
    return (
        <React.Fragment>
            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: getMobileAdjustedHeight(height, 500),
                    marginBottom: '50px'
                }
            }>
                <RenderIfVisible
                    defaultHeight={getMobileAdjustedHeight(height, 500) - 60}
                >
                    <GraphComponent ref={componentRef} GraphComp={ResponsiveLine} {...comps}/>

                </RenderIfVisible>
                <Box display="flex">
                    <Button
                        variant={"text"}
                        style={{marginTop: 5, marginBottom: 20}}
                        onClick={() => exportComponentAsPNG(componentRef)}
                    >
                        Export As PNG
                    </Button>
                </Box>
            </Paper>
        </React.Fragment>
    );

}


export default GraphWithPrint