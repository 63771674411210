import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../store/dataApi.reducer";
import LoadingSpinnerComponent from "../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {convert} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import MultiLineGraphComponent from "../../../components/Plots/MultiLinePlot/GraphComponent";

let path = 'procore/app_annie';

const ProcoreAppAnniePage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Procore - App Annie
            </Typography>
        </Container>
        <Container>
            {data === undefined ? <LoadingSpinnerComponent/> : <ProcoreAppAnnieGraphs data={data}/>}
        </Container>
    </>
}


const ProcoreAppAnnieGraphs = ({data}) => {
    return <>
        <MultiLineGraphComponent
            data={Object.keys(data['Procore'].daily_downloads).map(key => ({
                id: key,
                data: convert(data['Procore'].daily_downloads[key])
            }))}
            title={`Daily downloads`}
            xaxis={``}
        />
        <MultiLineGraphComponent
            data={Object.keys(data['Procore'].monthly_downloads).map(key => ({
                id: key,
                data: convert(data['Procore'].monthly_downloads[key])
            }))}
            title={`Monthly downloads`}
            xaxis={``}
        />
        <MultiLineGraphComponent
            data={Object.keys(data['Procore'].monthly_active_users).map(key => ({
                id: key,
                data: convert(data['Procore'].monthly_active_users[key])
            }))}
            title={`Monthly active users`}
            xaxis={``}
        />
        <MultiLineGraphComponent
            data={Object.keys(data['Autodesk'].daily_downloads).map(key => ({
                id: key,
                data: convert(data['Autodesk'].daily_downloads[key])
            }))}
            title={`Daily downloads`}
            xaxis={``}
        />
        <MultiLineGraphComponent
            data={Object.keys(data['Autodesk'].monthly_downloads).map(key => ({
                id: key,
                data: convert(data['Autodesk'].monthly_downloads[key])
            }))}
            title={`Monthly downloads`}
            xaxis={``}
        />
        <MultiLineGraphComponent
            data={Object.keys(data['Autodesk'].monthly_active_users).map(key => ({
                id: key,
                data: convert(data['Autodesk'].monthly_active_users[key])
            }))}
            title={`Monthly active users`}
            xaxis={``}
        />

    </>
}


export default ProcoreAppAnniePage
