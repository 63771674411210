import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchEvaluationData} from "../../../store/textCruncher.reducer";
import LoadingSpinnerComponent from "../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";


function withEvaluationDataFetchAndTitle(GraphComponent, evaluationId, title, maxWidth = "lg", sx = {}) {
    console.log(evaluationId)
    return function DataPageComponent() {
        let data = useSelector(state => state.textCruncher.evaluationData)
        let dispatch = useDispatch()
        useEffect(() => {
            if (data === undefined) {
                dispatch(fetchEvaluationData({evaluationID: evaluationId}))
            }
        }, [dispatch, data])

        return <>
            <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                <Typography variant="h4" color="inherit" component="div">
                    {title}
                </Typography>
            </Container>
            <Container maxWidth={maxWidth} sx={sx}>
                {data === undefined ? <LoadingSpinnerComponent/> : <>
                    <GraphComponent data={data}/>
                </>}
            </Container>
        </>
    };
}

export default withEvaluationDataFetchAndTitle